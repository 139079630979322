<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <!--    <vx-card ref="filterCard" title="Filtre" class="user-list-filters mb-4" actionButtons @refresh="resetColFilters" >-->
    <!--      <div class="vx-row">-->
    <!--        <div class="vx-col w-full">-->
    <!--          <p class="mb-2" > Pays </p>-->
    <!--          <v-select :options="countries" :reduce="rep => rep.id" :clearable="true" label="name" v-model="city" class="w-full"/>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </vx-card>-->
    <data-view-sidebar :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData" />

    <vs-table ref="table" pagination :max-items="itemsPerPage" search :data="products">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <!--        <vs-button type="border" icon-pack="feather" icon="icon-plus" @click="addNewData">AJOUTER</vs-button>-->

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
          <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ products.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : products.length }} of {{ products.length }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
      <template slot="thead">
        <!--        <vs-th sort-key="pays">Pays</vs-th>-->
        <!--        <vs-th sort-key="pays">Villes</vs-th>-->
        <vs-th sort-key="role">Profil</vs-th>
        <vs-th sort-key="last_name">Nom</vs-th>
        <vs-th sort-key="first_name">Prénoms</vs-th>
        <!--        <vs-th sort-key="birthDate">Date de naissance</vs-th>-->
        <!--        <vs-th sort-key="email">Email</vs-th>-->
<!--        <vs-th sort-key="Taux-de-commission">Taux de commissions</vs-th>-->
        <vs-th sort-key="Taux-de-transformation">Montant de la commission</vs-th>
        <vs-th sort-key="Taux-de-transformation">état de la commission</vs-th>
        <vs-th>Action</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <!--          <vs-td>-->
          <!--            <p class="product-name font-medium truncate">{{ tr.city.country.name }}</p>-->
          <!--          </vs-td>-->

          <!--          <vs-td>-->
          <!--            <p class="product-name font-medium truncate">{{ tr.city ? tr.city.name : '' }}</p>-->
          <!--          </vs-td>-->

          <vs-td >
            <vs-chip style="color: #FF9F43 " class="product-role">{{ roleObject[tr.role] }}</vs-chip>
          </vs-td>

          <vs-td>
            <p class="product-first_name">{{ tr.last_name}}</p>
          </vs-td>

          <vs-td >
            <p class="product-last_name">{{ tr.first_name}}</p>
          </vs-td>

          <!--          <vs-td>-->
          <!--            <p class="product-birthDate">{{ tr.birthDate}}</p>-->
          <!--          </vs-td>-->

          <!--          <vs-td>-->
          <!--            <p :style="'color: ' + getOrderStatusColor()" class="product-email">{{ tr.email}}</p>-->
          <!--          </vs-td>-->

<!--          <vs-td>-->
<!--            <p :style="'color: ' + getOrderStatusColor()" class="Taux-de-commission">{{ tr.commissionRate}}%</p>-->
<!--          </vs-td>-->

          <vs-td>
            <p :style="'color: ' + getOrderStatusColor()" class="Taux-de-transformation">{{ tr.commissionToPay}}</p>
          </vs-td>
          <vs-td>
            <p :style="'color: ' + getOrderStatusColor()" class="product-order-status">{{ PayObject[tr.commissionToPay]}}</p>
          </vs-td>

          <vs-td class="whitespace-no-wrap">
            <div class="flex">
              <vx-tooltip text="Détail" color="primary">
                <feather-icon style="color: navy " icon="EyeIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click="activePrompt2 = true" v-on:click="openPlayerDialog(tr)" color="warring" type="border" />
              </vx-tooltip>
              <vx-tooltip v-if="tr.commissionToPay!==0" text="Payer" color="warning">
                <feather-icon style="color: #FF9F43 " icon="DollarSignIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="pay(tr.id)" class="ml-4" />
              </vx-tooltip>
              <!--            <feather-icon style="color: red"  icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData(tr.id)" />-->
            </div>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vs-prompt title="DÉTAILS DE LA COMMISSION TOTALE PAYEE" buttons-hidden @close="close" :active.sync="activePrompt2">
      <div class="con-exemple-prompt" v-if="selectedPlayer">
        <vs-input readonly label="NOM "  v-model=" selectedPlayer.last_name" class="mt-4 mb-2 w-full" />
        <vs-input readonly label="PRENOMS "  v-model=" selectedPlayer.first_name" class="mt-4 mb-2 w-full" />
        <vs-input readonly label=" COMMISSION TOTALE PAYÉE"  v-model=" selectedPlayer.commissionPaid " class="mt-4 mb-2 w-full" />
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import DataViewSidebar from '../commissionsPayAll/DateViewSidebarCommissionsPayAll'
import moduleDataListContributors from "@/store/contributors/moduleDataContributors"
const color_array = ['#FF9F43', '#28C76F', '#EA5455', '#7367F0']
let iopi = 0
export default {
  components: {
    DataViewSidebar
  },
  data() {
    return {
      selected: [],
      PayObject: {
        0: 'PAYÉE',
      },
      city: '',
      countries: [],
      selectedPlayer:undefined,
      // products: [],
      activePrompt2:false,
      itemsPerPage: 4,
      isMounted: false,
      roleObject: {
        admin: 'Administrateur',
        contributor: "Apporteur d'affaire",
        prospect: 'Prospect',
        recipient: 'Bénéficaire'
      },
      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},
    }
  },
  watch: {
    city (value) {
      if (value) {
        this.$vs.loading()
        this.$store.dispatch("dataListContributors/fetchContributorsByCountry", value)
          .then(() => {this.$vs.loading.close()})
          .catch(() => {this.$vs.loading.close()})

      } else {
        this.$vs.loading()
        this.$store.dispatch("dataListContributors/fetchContributors")
          .then(() => {this.$vs.loading.close()})
          .catch(() => {this.$vs.loading.close()})
      }
    }
  },
  computed: {
    currentPage() {
      if(this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    products() {
      return this.$store.state.dataListContributors.contributors
    }
  },
  methods: {
    resetColFilters () {
      this.$refs.filterCard.removeRefreshAnimation()
    },

    addNewData() {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },
    openPlayerDialog: function(player) {
      this.selectedPlayer = player;
    },
    deleteData(id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: 'Confirmer la suppression?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          await this.$store.dispatch("dataListContributors/removeContributors", id)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'SUPPRESSION',
            text: 'Apportteur supprimé',
            color: 'success',
            position:'top-center'
          }).catch(err => { console.error(err) })
        }
      })

    },

    pay(id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: 'Confirmer le payement?',
        acceptText: 'Confirmer',
        cancelText: 'Annuler',
        accept: async () => {
          this.$vs.loading()
          await this.$store.dispatch("dataListContributors/pay_all_commission", id)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'PAYEMENT',
            text: 'Payement effectué',
            color: 'success',
            position:'top-center'
          }).catch(err => { console.error(err) })
        }
      })

    },

    editData(data) {
      this.sidebarData = data
      this.toggleDataSidebar(true)
    },
    getOrderStatusColor () {
      const libelle = color_array[iopi]
      if ((iopi + 1) < color_array.length) {
        iopi++
      } else {
        iopi = 0
      }
      return libelle
    },
    getAllContributors () {
      this.$vs.loading()
      this.$http.get('contributors/')
        .then((response) => {
          this.contributors = response.data
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log('error: ', error)
          this.$vs.loading.close()
        })
    },
    getAllContributorsFilter (city) {
      this.$vs.loading()
      this.$http.post('contributor_filter/', { city })
        .then((response) => {
          this.contributors = response.data
          this.$vs.loading.close()
        })
        .catch((error) => {
          console.log('error: ', error)
          this.$vs.loading.close()
        })
    },
    toggleDataSidebar(val=false) {
      this.addNewDataSidebar = val
    }
  },
  created() {
    if(!moduleDataListContributors.isRegistered) {
      this.$store.registerModule('dataListContributors', moduleDataListContributors)
      moduleDataListContributors.isRegistered = true
    }
    this.$vs.loading()
    this.$store.dispatch("dataListContributors/fetchContributors")
      .then(() => { this.$vs.loading.close() })

    this.$http.get("countries")
      .then((response) => { this.countries = response.data })
      .catch((error) => { console.log(error) })

  },
  mounted() {
    this.isMounted = true;
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr{
        box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
        td{
          padding: 20px;
          &:first-child{
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }
          &:last-child{
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }
        td.td-check{
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
